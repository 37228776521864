<template>
  <div class="container">
    <MigreliaHeader/>
    <div v-if="initData">
      <h3>Init Data</h3>
      <pre>{{ initData }}</pre>
    </div>
    <hr>
    <div v-if="initDataUnsafe">
      <h3>Init Data Unsafe</h3>
      <pre>{{ initDataUnsafe }}</pre>
    </div>
    <MigraineList/>
  </div>
</template>

<script>
import MigraineList from '@/components/MigraineList.vue';
import MigreliaHeader from "@/components/MigreliaHeader.vue";

export default {
  name: 'HomeView',
  data() {
    return {
      initData: null,
      initDataUnsafe: null,
    };
  },
  mounted() {
    if (window.Telegram.WebApp) {
      const initData = window.Telegram.WebApp.initData;
      const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;

      console.log('initData:', initData);
      console.log('initDataUnsafe:', initDataUnsafe);

      this.initData = initData;
      this.initDataUnsafe = initDataUnsafe;
    }
  },
  components: {
    MigraineList,
    MigreliaHeader,
  }
};
</script>

<style>
.container {
  background-color: white;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  width: 80%;
  height: 90%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}
@media (max-width: 768px) {
  .container {
    padding-top: 100px;
    width: 100%;
    height: 100%;
  }
}
</style>
