import {createApp} from 'vue';
import router from "@/router";

import App from './App.vue';
import VueTelegram from 'vue-tg';

createApp(App)
    .use(router)
    .use(VueTelegram)
    .mount('#app')
