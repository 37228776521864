import {createMemoryHistory, createRouter} from 'vue-router';
import HomeView from '@/views/HomeView.vue';


const routes = [
    { path: '/', name: 'HomeView', component: HomeView },
];

export default new createRouter({
    history: createMemoryHistory(),
    routes
});
