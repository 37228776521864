<script setup>
</script>

<template>
  <header>
    <img src="@/assets/logo.png" alt="Migrelia Robot Logo">
    <h1 class="header-text">Migrelia Robot</h1>
  </header>
</template>

<style scoped>
header {
  display: flex;
  text-align: center;
  margin-bottom: 20px;
}

header img {
  width: 200px;
  margin-bottom: 10px;
}

.header-text {
  color: #ffb3b3;
  margin-left: 100px;
  margin-top: 100px;
}

@media (max-width: 768px) {
  header {
    flex-direction: column;
  }

  .header-text {
    margin-left: 0;
    margin-top: 10px;
  }

  header img {
    width: 50px;
    margin-left: 80px;
    margin-bottom: 10px;
  }
}
</style>
