<template>
  <div class="log-list">
    <div v-for="log in logs" :key="log.id" class="log-entry" @click="goToDetails(log.id)">
      <h2>Дата начала: {{ log.startDate }}</h2>
      <p>Дата окончания: {{ log.endDate }}</p>
      <p>Принятые лекарства: {{ log.medication }} ({{ log.medicationDate }})</p>
      <p>Заметки: {{ log.notes }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MigraineList',
  data() {
    return {
      logs: [
        {
          id: 1,
          startDate: '2024-07-20',
          endDate: '2024-07-21',
          medication: 'Парацетамол, Ибупрофен',
          medicationDate: '2024-07-20',
          notes: 'Начал учить иностранный язык и через 5 минут заболела голова. Не знаю точно ли это из-за мигрени, но давит на уши и глаза. Просто неприятное чувство в голове, она не болит.'
        },
        {
          id: 2,
          startDate: '2024-07-15',
          endDate: '2024-07-15',
          medication: 'Аспирин',
          medicationDate: '2024-07-15',
          notes: 'Левая ноздря, левая сторона. Закончилось еще вчера.'
        }
        // Добавьте больше записей по мере необходимости
      ]
    };
  }
};
</script>


<style>
.log-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.log-entry {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.3s;
  cursor: pointer;
}
.log-entry:hover {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}
.log-entry h2 {
  margin: 0 0 10px;
  font-size: 18px;
  color: #ffb3b3;
}
.log-entry p {
  margin: 5px 0;
  color: #555;
}
</style>
